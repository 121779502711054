import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import SEO from '../components/SEO';

const DvdGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
`;

export default function SingleDVDPage({ data: { dvd } }) {
  return (
    <>
      {/* Question mark ensures that each node or asset exists before chaining and rendering the value */}
      <SEO title={dvd.name} image={dvd.image?.asset?.fluid?.src} />
      <DvdGrid>
        <Img fluid={dvd.image.asset.fluid} />
        <div>
          <h2>{dvd.name}</h2>
        </div>
      </DvdGrid>
    </>
  );
}

// This is dynanmic based on the slug passed in via context
export const query = graphql`
  query($slug: String!) {
    dvd: sanityDvd(slug: { current: { eq: $slug } }) {
      name
      id
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
